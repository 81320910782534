/* Tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: UbuntuLocal;
    src: local('Ubuntu');
  }

  @font-face {
    font-family: UbuntuLocal;
    font-weight: 500;
    src: local('Ubuntu Medium');
  }

  @font-face {
    font-family: UbuntuLocal;
    font-weight: 700;
    src: local('Ubuntu Bold');
  }

  @font-face {
    font-family: -apple-system, BlinkMacSystemFont, 'Ubuntu', 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  }

  body,
  p {
    @apply text-sm font-normal leading-tight text-neutral-900 tracking-normal;
    font-family: -apple-system, BlinkMacSystemFont, UbuntuLocal, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif !important;
  }

  h1 {
    @apply text-xl font-bold leading-7;
  }
  h2 {
    @apply text-sm font-medium leading-none;
  }
  input {
    @apply text-sm leading-tight;
  }
  button {
    @apply text-sm leading-relaxed;
  }

  .font-h1 {
    @apply text-xl font-bold leading-7;
  }
  .font-h2 {
    @apply text-sm font-bold leading-none;
  }
  .font-input {
    @apply text-sm;
  }
  .font-button {
    @apply text-sm font-semibold leading-relaxed;
  }
  .font-p1 {
    @apply text-sm font-medium leading-tight;
  }
  .font-p2 {
    @apply text-xs font-medium leading-none;
  }
  .font-p3 {
    @apply text-xs font-medium leading-none;
  }
  .font-p4 {
    @apply text-[11px] font-medium leading-none;
  }

  th > * {
    text-transform: uppercase;
  }

  svg {
    pointer-events: none;
  }
}

.appearance-number-none::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.appearance-number-none::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: rgb(200, 200, 200);
}

::-webkit-scrollbar-thumb:hover {
  background: #0021f5;
}

input:-webkit-autofill {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 50px white inset;
}

::-webkit-search-decoration,
::-webkit-search-cancel-button,
::-webkit-search-results-button,
::-webkit-search-results-decoration {
  display: none;
  opacity: 0;
}

.intercom-launcher-frame,
.intercom-lightweight-app-launcher {
  transform: scale(0.8) !important;
}
