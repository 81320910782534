.slider {
  @apply appearance-none rounded-lg w-full outline-none h-1;
}

.slider::-webkit-slider-thumb:hover {
  @apply cursor-pointer;
}

.slider::-webkit-slider-thumb {
  @apply bg-primary-500 rounded-full border-2 border-solid border-white h-4 w-4 transition-transform shadow-xs appearance-none;
}

.slider::-moz-range-thumb {
  @apply bg-primary-500 rounded-full border-2 border-solid border-white h-4 w-4 transition-transform shadow-xs appearance-none;
}

.slider::-ms-thumb {
  @apply bg-primary-500 rounded-full border-2 border-solid border-white h-4 w-4 transition-transform shadow-xs appearance-none;
}

.slider:active::-webkit-slider-thumb {
  @apply scale-105;
}
