.react-select__control {
  min-height: inherit !important;
  @apply rounded-md border-neutral-100 shadow-xs h-full transition-shadow hover:cursor-pointer !important;
}

.react-select--is-disabled .react-select__control {
  @apply rounded-md border-transparent hover:cursor-default !important;
  box-shadow: none !important;
}

.react-select__control:hover {
  @apply border shadow !important;
}

.react-select__control--is-focused {
  @apply ring ring-primary-100 border-primary-200 !important;
}

.react-select__control--is-focused:hover {
  @apply ring ring-primary-100 border-primary-200 !important;
}

.react-select-error .react-select__control {
  @apply border-error-default !important;
}

.react-select-error .react-select__control--is-focused {
  @apply border-primary-200 !important;
}

.react-select__indicator-separator {
  display: none !important;
}

.react-select__single-value,
.react-select__option:not(.react-select__option--is-selected) {
  @apply text-neutral-800 !important;
}

.react-select__dropdown-indicator {
  @apply py-0 !important;
  @apply pl-1 !important;
  @apply text-neutral-400 !important;
}

.react-select--is-disabled .react-select__dropdown-indicator {
  @apply opacity-60 !important;
}

.react-select__clear-indicator {
  @apply py-0 !important;
}

.react-select__multi-value {
  @apply bg-neutral-50 rounded !important;
}

.react-select__multi-value__label {
  @apply text-neutral-800 text-xs font-normal p-1 flex items-center !important;
}

.react-select__menu {
  @apply shadow-lg border-none rounded-lg !important;
  @apply overflow-hidden !important;
  @apply z-20 !important;
}

.react-select__menu-list {
  @apply p-0 rounded !important;
}

.react-select__single-value--is-disabled {
  @apply text-neutral-300 !important;
}

.react-select__multi-value--is-disabled .react-select__multi-value__label {
  @apply text-neutral-300 !important;
}
